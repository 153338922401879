import React, { useEffect, useState } from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  Typography,
  Chip,
  Stack
} from '@mui/material';
import Header from 'components/Headers/Header.js';

import componentStyles from 'assets/theme/views/admin/tables.js';
import CommonTable from 'components/Tables/CommonTable';
import { dataService } from 'services/data-service';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PhoneAndroid as PhoneIcon,  CheckCircle as VerifiedIcon, Cancel as UnverifiedIcon } from '@mui/icons-material';
import DeviceDetailsDialog from './DeviceDetailsDialog';

const useStyles = makeStyles(componentStyles);

const DevicesList = () => {

  const classes = useStyles();
  const [deviceList, setDeviceList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState({
    isVisible: false,
    data: {}
  });
  const columns = [
    {
      id: 'Device',
      numeric: false,
      disablePadding: true,
      label: 'Device ID',
      dataType: 'component',
      Component: ({ row }) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center" onClick={() => viewAction(row)}>
            <PhoneIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: 'primary.main',
                letterSpacing: '0.5px'
              }}
            >
              {`RD-${row?.DeviceId}`}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: 'SystemInfo',
      numeric: false,
      disablePadding: false,
      label: 'System Info',
      dataType: 'component',
      Component: ({ row }) => (
        <Stack spacing={0.5}>
          <Typography variant="body2" color="text.primary">
            {row.Brand}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {row.Model} (OS-{row.SystemVersion})
          </Typography>
        </Stack>
      ),
    },
    {
      id: 'UserName',
      numeric: false,
      disablePadding: false,
      label: 'User',
      dataType: 'string',
    },
    {
      id: 'WorkCenterNames',
      numeric: false,
      disablePadding: false,
      label: 'WorkCenter',
      dataType: 'string',
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      dataType: 'component',
      Component: ({ row }) => {
        const isVerified = row?.VerifiedAt && !row?.DeletedAt;
        return (
          <Chip
            icon={isVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
            label={isVerified ? 'Verified' : 'Unverified'}
            size="small"
            color={isVerified ? 'success' : 'error'}
            variant={isVerified ? 'filled' : 'outlined'}
            sx={{
              borderRadius: 1,
              '& .MuiChip-icon': {
                fontSize: 16
              }
            }}
          />
        );
      },
    },
    {
      id: 'CreatedAt',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
      dataType: 'date',
    },
  ];

  useEffect(() => {
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload]);

  const fetchData = async () => {
    setIsLoading(true);
    let res = await dataService(
      'get',
      'DevicesList',
      `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`
    );
    setDeviceList(res?.data);
    setTotalRecord(res?.totalRecord);
    setIsLoading(false);
  }

  const viewAction = async (row) => {
    try {
      setIsLoading(true);
      let res = await dataService('get', `${"GetDeviceDetails"}?DeviceId=${row?.DeviceId}`, null);
      let resUsers = await dataService('get', `GetDeviceUsers`, null);
      setOpenDialog({
        isVisible: true,
        data: {
          DeviceId: row.DeviceId,
          Model: row.Model,
          UserDetails: res?.data?.UserDetails || [],
          WorkCenterDetails: res?.data?.WorkCenterDetails,
          UserOptions: new Set(resUsers?.data.filter(device => res?.data?.UserDetails?.[0]?.UserId !== device?.UserId)),
        },
      });
    } catch (error) {
      console.error("Error fetching device details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog({
      isVisible: false,
      data: {}
    });
    setIsReload(!isReload);
  };

  const actionList = [
    {
      label: 'Update',
      icon: VisibilityIcon,
      iconColor: 'primary.main',
      action: viewAction,
      tooltip: 'View device details'
    },
  ];


  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Devices List"
          columns={columns}
          rows={deviceList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          searchText={searchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
        />
      </Container>
      {openDialog.isVisible &&
      <DeviceDetailsDialog
        open={openDialog.isVisible}
        onClose={handleCloseDialog}
        device={openDialog.data}
      />
      }
    </>
  );
};

export default DevicesList;
