import React, { useState, useEffect } from "react";
import { Popover, List, ListItem, ListItemText, Typography, Box, Badge, IconButton, Divider, useTheme, useMediaQuery, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { dataService } from "services/data-service";
import { NotificationsOutlined } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { formatMessage } from "utils/helper";
import OrdersCommentsDialog from "./OrdersCommentsDialog";
import useSocket from "hooks/useSocket";

export default function NotificationPopover() {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [notifications, setNotifications] = useState([]);
    const [viewDetailDialog, setViewDetailDialog] = useState({
        isVisible: false,
        data: null,
        extraData: null
    });
    const [isReload, setIsReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const unreadNotificationCount = useSocket('unreadNotificationCount', 0);
    const [localUnreadNotificationCount, setLocalUnreadNotificationCount] = useState(unreadNotificationCount);

    useEffect(() => {
        setLocalUnreadNotificationCount(unreadNotificationCount);
    }, [unreadNotificationCount]);

    const open = Boolean(anchorEl);

    const fetchNotifications = async () => {
        try {
            let response = await dataService('get', 'NotificatiosList', `searchText=${""}&sortBy=${"desc"}&sortOrder=${"CreatedAt"}&page=${1}&offset=${10}&userId=${currentUser()?.UserId}&showUnreadOnly=${0}`);
            setNotifications(response.data);
        } catch (error) {
            console.error("Failed to fetch notifications:", error);
        }
    };

    useEffect(() => {
        if (open) {
            fetchNotifications();
        }
    }, [open]);

    const handleOpen = (event) => {
        notificationsUnreadStatusUpdate();
        setAnchorEl(event.currentTarget);
    };

    const notificationsUnreadStatusUpdate = async () => {
        try {
            let response = await dataService('get', `NotificationsUnreadStatusUpdate/${currentUser()?.UserId}`, '');
            if (response.status === 200) {
                setLocalUnreadNotificationCount(0);
            }
        } catch (error) {
            console.error("Failed to update notification status:", error);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewAll = () => {
        handleClose();
        history.replace('/admin/notifications');
    };

    const handleOpenCommnets = async (row) => {
        setAnchorEl(null);
        try {
            let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
            if (res.status === 200 && res?.data) {
                setViewDetailDialog({
                    isVisible: true,
                    data: res?.data,
                    extraData: { ProductName: row?.ProductName || "", Quantity: row?.Quantity || "", CustomerName: row?.CustomerName || "", FileName: row?.FileName || "", OrderNumber: row?.OrderNumber || "", OrderId: row?.OrderId }
                });
            }
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    }

    return (
        <>
            <IconButton color="inherit" onClick={handleOpen}>
                <Badge badgeContent={localUnreadNotificationCount} color="error"
                    sx={{
                        "& .MuiBadge-badge": {
                            fontSize: "0.7rem",
                            height: "20px",
                            minWidth: "16px",
                            padding: "0 4px",
                        },
                    }}
                >
                    <NotificationsIcon color={iMDScreen ? "black" : "white"} style={{ width: 25, height: 30, marginRight: 0 }} />
                </Badge>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Box
                    sx={{
                        minWidth: 360,
                        maxHeight: 700,
                        display: "flex",
                        flexDirection: "column",
                        bgcolor: "background.paper",
                        borderRadius: 2,
                    }}
                >
                    <List
                        sx={{
                            p: 0,
                            overflow: "auto",
                            maxHeight: 500,
                            '& .MuiListItem-root': {
                                transition: 'background-color 0.2s',
                                '&:hover': {
                                    bgcolor: 'action.hover',
                                },
                            },
                        }}
                    >
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <Box key={notification.NotificationsId}>
                                    <ListItem
                                        button
                                        onClick={() => handleOpenCommnets(notification)}
                                        sx={{ py: 1.5 }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" sx={{ mb: 0.5 }}>
                                                    Order:{" "}
                                                    <Box component="span" sx={{ fontWeight: 600 }}>
                                                        #{notification.OrderNumber}
                                                    </Box>
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{ lineHeight: 1.4 }}
                                                >
                                                    {formatMessage(notification.Message, currentUser()?.UserId)}
                                                </Typography>
                                            }
                                        />
                                        <IconButton
                                            edge="end"
                                            size="small"
                                            sx={{ color: "action.active", ml: 1 }}
                                        >
                                            <NotificationsOutlined fontSize="large" sx={{ width: 20, height: 20 }} />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </Box>
                            ))
                        ) : (
                            <Box
                                sx={{
                                    py: 4,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant="body2" color="text.secondary">
                                    No notifications
                                </Typography>
                            </Box>
                        )}
                    </List>

                    <Box
                        sx={{
                            p: 2,
                            borderTop: 1,
                            borderColor: "divider",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            onClick={handleViewAll}
                            sx={{
                                color: "primary.main",
                                fontWeight: 600,
                                textTransform: "none",
                                '&:hover': {
                                    bgcolor: 'primary.lighter',
                                },
                            }}
                        >
                            View All
                        </Button>
                    </Box>
                </Box>
            </Popover>

            {viewDetailDialog?.isVisible &&
                <OrdersCommentsDialog
                    isOrderFlow={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setIsReload={setIsReload}
                    isReload={isReload}
                    open={viewDetailDialog}
                    setOpen={setViewDetailDialog}
                    onClose={() => {
                        setViewDetailDialog({ data: null, isVisible: false, extraData: null })
                    }} />}
        </>
    );
}
