import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
  Tooltip,
  AppBar,
  Toolbar,
  Container,
  Popover
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { currentUser } from 'services/user-service';
import { useSelector } from 'react-redux';
import { getWorkCenterListForStore } from 'services/data-service';
import { getProductListForStore } from 'services/data-service';
import { makeStyles } from '@mui/styles';
import componentStyles from "assets/theme/components/sidebar.js";

const DRAWER_WIDTH = 250;
const COLLAPSED_WIDTH = 80;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    position: 'relative',
    flexGrow: 1,
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${open ? DRAWER_WIDTH : COLLAPSED_WIDTH}px)`,
    },
    width: '100%',
  }),
);

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '& .admin-header-main': {
    width: '100%',
    '& .MuiAppBar-root': {
      width: '100%',
      backgroundColor: 'linear-gradient(87deg,#11cdef,#1171ef)',
      color: theme.palette.common.white,
    }
  }
}));

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
  transition: theme.transitions.create(['width', 'transform'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: open ? DRAWER_WIDTH : COLLAPSED_WIDTH,
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['width', 'transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      height: '100%',
    },
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2),
  minHeight: 64,
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const useStyles = makeStyles(componentStyles);

export default function DrawerBar({ routes, logo, dropdown, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(isDesktop);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [orderFlow, setOrderFlow] = React.useState([]);
  const [selectedOrderFlowIndx, setSelectedOrderFlowIndx] = React.useState(null);
  const selectedOrderFlow = useSelector(state => state.selectedOrderFlow);

  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState(null);

  useEffect(() => {
    if (isDesktop) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isDesktop]);

  const handleDrawerToggle = () => {
    if (isDesktop) {
      setOpen(!open);
    } else {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    generateMenu();
  }, [selectedOrderFlow])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await getProductListForStore();
    await getWorkCenterListForStore();
    generateMenu()
  }

  const generateMenu = () => {

    let selected_flow = selectedOrderFlow;

    if (selected_flow?.selected_flow) {
      selected_flow = selected_flow?.selected_flow;
    }
    else {
      if (localStorage.getItem('selected_flow')) {
        selected_flow = JSON.parse(localStorage.getItem('selected_flow'))
      }
    }

    if (selected_flow) {
      let ordFlow = selected_flow?.workcenters;
      if (selected_flow?.product?.ProductId === 0) {
        ordFlow = uniquWorkCenters(selected_flow?.workcenters);
      }
      else {
        ordFlow = selected_flow?.workcenters?.filter((worCenter) => { return Number(worCenter.ProductId) === Number(selected_flow?.product?.ProductId) })
      }

      if (ordFlow) {
        setOrderFlow(ordFlow);
      }

    }
  };

  const uniquWorkCenters = (workcentersArr) => {
    var items = workcentersArr;
    var result = [];
    items.forEach(item => {
      let findW = result?.find(rs => { return rs?.WorkCenterName === item?.WorkCenterName })
      if (!findW) {
        result.push(item);
      }
    });
    if (result?.length > 0) {
      result.sort((a, b) => a.WorkCenterOrder - b.WorkCenterOrder)
    }
    return result;
  }

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopoverId(null);
  };

  const renderNavItems = (routes) => {
    return routes.filter(prop => (
      prop.showInSidebar && prop?.roles?.includes(`${currentUser()?.Role}`)
    )).map((prop, key) => {
      if (prop.divider) {
        return <Divider key={key} sx={{ my: 1 }} />;
      } else if (prop.title) {
        return (
          <>
            {(open || mobileOpen) && (
              <Typography
                key={key}
                variant="h6"
                component="h6"
                classes={{ root: classes.title }}
                className="text-bold text-base bg-gray-50"
              >
                {prop.title}
              </Typography>
            )}
          </>
        );
      }

      const isSelected = selectedItem === (prop.layout + prop.path);

      let textContent = (
        <>
          <Tooltip title={prop.name} placement="right" arrow>
            <ListItemIcon>
              {typeof prop.icon === "string" ? (
                <Box component="i" className={prop.icon} />
              ) : null}
              {typeof prop.icon === "object" || typeof prop.icon === "function" ? (
                <Box
                  component={prop.icon}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  sx={{
                    color: isSelected ? 'primary.main' : 'inherit',
                    marginRight: '4px',
                  }}
                />
              ) : null}
            </ListItemIcon>
          </Tooltip>
          {(open || mobileOpen) && (
            <Typography
              sx={{
                fontSize: '0.9rem !important',
                color: isSelected ? 'primary.main' : 'inherit',
                fontWeight: isSelected ? 600 : 400,
                ml: '-10px'
              }}
            >
              {prop.name}
            </Typography>
          )}
        </>
      );

      if (prop.accordian && (!open && !mobileOpen)) {
        return (
          <React.Fragment key={key}>
            <ListItem
              button
              onClick={(event) => handlePopoverOpen(event, key)}
              onMouseEnter={(event) => handlePopoverOpen(event, key)}
              sx={{
                pl: 2.5,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              {textContent}
            </ListItem>
            <Popover
              id={`popover-${key}`}
              open={openPopoverId === key}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              onMouseLeave={handlePopoverClose}
              PaperProps={{
                onMouseEnter: (e) => {
                  e.stopPropagation();
                },
                sx: {
                  width: 200,
                  height: 500,
                  boxShadow: (theme) => theme.shadows[8],
                  borderRadius: 2,
                  my: 1.5,
                  p: 0,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                },
              }}
            >
              <Box
                sx={{ p: 0 }}
                onMouseLeave={handlePopoverClose}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    mb: 1.5,
                    color: 'primary.main',
                    px: 2,
                    pt: 1,
                  }}
                >
                  {prop.name}
                </Typography>
                <List
                  sx={{
                    p: 0,
                    '& .MuiListItem-root': {
                      borderRadius: 1,
                      mb: 0.5,
                      '&:last-child': {
                        mb: 1,
                      },
                    },
                  }}
                >
                  {orderFlow?.map((order, idx) => (
                    <ListItem
                      key={idx}
                      component={Link}
                      to={`${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`}
                      onClick={handlePopoverClose}
                      sx={{
                        py: 0.5,
                        px: 2,
                        '&:hover': {
                          bgcolor: 'primary.lighter',
                          '& .MuiTypography-root': {
                            color: 'primary.main',
                          },
                        },
                        bgcolor: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}` ? 'rgba(94, 114, 228, 0.08)' : 'transparent',
                        borderRight: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}` ? '3px solid' : 'none',
                        borderColor: 'primary.main',
                        '& .MuiTypography-root': {
                          color: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}` ? 'primary.main' : 'inherit',
                        },
                      }}
                    >
                      <ListItemText
                        primary={order.WorkCenterName}
                        primaryTypographyProps={{
                          variant: 'body2',
                          sx: {
                            transition: 'color 0.2s',
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Popover>
          </React.Fragment>
        );
      }

      const handleItemClick = () => {
        if (!isDesktop) {
          setMobileOpen(false);
        }
        setSelectedItem(prop.layout + prop.path);
      };

      return (
        <>
          {!prop?.accordian && (
            <ListItem
              key={key}
              component={Link}
              onClick={handleItemClick}
              to={prop.layout + prop.path}
              sx={{
                px: 2.5,
                '&:hover': {
                  bgcolor: 'action.hover',
                },
                bgcolor: isSelected ? 'rgba(94, 114, 228, 0.08)' : 'transparent',
                borderRight: isSelected ? '3px solid' : 'none',
                borderColor: 'primary.main',
                '& .MuiTypography-root': {
                  color: isSelected ? 'primary.main' : 'inherit',
                },
                '& .MuiListItemIcon-root': {
                  color: isSelected ? 'primary.main' : 'inherit',
                },
              }}
            >
              {textContent}
            </ListItem>
          )}
          {prop?.accordian && (open || mobileOpen) && orderFlow?.map((order, iKey) => (
            <ListItem
              key={iKey}
              component={Link}
              onClick={() => {
                setSelectedOrderFlowIndx(iKey);
                setSelectedItem(`${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`);
              }}
              to={`${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`}
              sx={{
                pl: 4,
                pr: 2.5,
                '&:hover': {
                  bgcolor: 'action.hover',
                },
                bgcolor: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`
                  ? 'rgba(94, 114, 228, 0.08)'
                  : 'transparent',
                borderRight: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`
                  ? '3px solid'
                  : 'none',
                borderColor: 'primary.main',
                '& .MuiTypography-root': {
                  color: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`
                    ? 'primary.main'
                    : 'inherit',
                },
                '& .MuiListItemIcon-root': {
                  color: selectedItem === `${prop.layout}/orderflow/${order.WorkCenterName?.replace(' ', '-').toLowerCase()}/${order.WorkCenterId}`
                    ? 'primary.main'
                    : 'inherit',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 35 }}>
                <ArrowForwardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2" sx={{ fontSize: '0.9rem !important' }}>{order.WorkCenterName}</Typography>} />
            </ListItem>
          ))}
        </>
      );
    });
  };



  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default
    }}>
      {!isDesktop && (
        <AppBar
          position="fixed"
          color="default"
          elevation={0}
          sx={{
            width: '100%',
            zIndex: theme.zIndex.drawer + 2,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
        >
          <Toolbar>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1,
                px: 2,
                width: '100%',
                maxWidth: '100% !important'
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon sx={{ width: 25, height: 25 }} />
              </IconButton>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {dropdown}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      )}

      <StyledDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            height: `calc(100% - 40px)`,
            zIndex: theme.zIndex.drawer + 1,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.paper,
            marginTop: '50px',
          },
        }}
      >
        <DrawerHeader>
          <Box component={Link} to={logo.innerLink}>
            <img src={logo.imgSrc} alt={logo.imgAlt} style={{ height: 35 }} />
          </Box>
          <IconButton onClick={() => setMobileOpen(false)} size="small">
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
          {renderNavItems(routes)}
        </Box>
      </StyledDrawer>

      <StyledDrawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
      >
        <DrawerHeader>
          <Box component={Link} to={logo.innerLink}>
            <img
              src={(open || mobileOpen) ? logo.imgSrc : require('../../assets/img/brand/app-icon-114.png')}
              alt={logo.imgAlt}
              style={{
                height: 35,
                width: open ? 'auto' : 35,
                transition: theme.transitions.create('width')
              }}
            />
          </Box>
          <IconButton onClick={handleDrawerToggle} size={open ? 'medium' : 'small'}>
            <MenuIcon sx={{ width: open ? '25px !important' : '20px !important', height: open ? '25px !important' : '20px !important' }} />
          </IconButton>
        </DrawerHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {renderNavItems(routes)}
        </Box>
      </StyledDrawer>

      <Main open={open}>
        <ContentWrapper>
          {!isDesktop && <Box sx={{ height: '64px' }} />}
          <Box className="admin-header-main">
            {children}
          </Box>
        </ContentWrapper>
      </Main>
    </Box>
  );
}

DrawerBar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
      category: PropTypes.string,
      layout: PropTypes.string,
      showInSidebar: PropTypes.bool,
      roles: PropTypes.arrayOf(PropTypes.string),
      info: PropTypes.string
    })
  ).isRequired,
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
  }).isRequired
}; 