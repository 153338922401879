import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, DialogContent, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, ExpandMore, Send } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { dataService } from 'services/data-service';
import { currentUser } from "services/user-service";
import MyTaskDetailViewDialog from './MyTaskDetailViewDialog';
import { formatMessage } from 'utils/helper';
import { Mention, MentionsInput } from 'react-mentions';
import moment from 'moment';
import { useTheme } from '@emotion/react';
import useSocketComment from 'hooks/useSocketComment';


function OrdersCommentsDialog({ isOrderFlow, isLoading, setIsLoading, onClose, open, setOpen, setIsReload, isReload }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        data: comments,
        emitEvent,
        listenToEvent,
        setData,
        joinOrderRoom,
        connected
    } = useSocketComment(null);

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [isCommentsLoading, setIsCommentsLoading] = useState(true);

    const handleClose = () => {
        onClose(false);
    };

    const [viewDetailDialog, setViewDetailDialog] = useState({
        isVisible: false,
        data: null,
        extraData: null
    });
    const [showSubmitLoading, setShowSubmitLoading] = useState(false);
    const [users, setUserList] = useState([]);

    const commentsContainerRef = useRef(null);

    useEffect(() => {
        console.log('open comments dialog: ', open)
        if (open?.isVisible) {
            setIsCommentsLoading(true);
        } else {
            setData(null);
            setIsCommentsLoading(false);
        }
    }, [open?.isVisible]);

    useEffect(() => {
        let mounted = true;

        if (open?.isVisible && open?.extraData?.OrderId && connected) {
            joinOrderRoom(open.extraData.OrderId);

            const removeCommentsListener = listenToEvent("commentsList", (data) => {
                if (!mounted) return;
                if (data?.comments && data.orderId === open.extraData.OrderId) {
                    setData(data);
                    setIsCommentsLoading(false);
                }
            });

            const removeNewCommentListener = listenToEvent("newComment", (newComment) => {
                if (!mounted) return;
                if (newComment.OrderId === open.extraData.OrderId) {
                    setIsCommentsLoading(true);
                    emitEvent("getOrderComments", {
                        orderId: open.extraData.OrderId,
                        userId: currentUser()?.UserId
                    });
                }
            });

            emitEvent("getOrderComments", {
                orderId: open.extraData.OrderId,
                userId: currentUser()?.UserId
            });

            return () => {
                mounted = false;
                removeCommentsListener();
                removeNewCommentListener();
            };
        }
    }, [open?.isVisible, open?.extraData?.OrderId, connected]);

    useEffect(() => {
        if (commentsContainerRef.current && open?.isVisible) {
            commentsContainerRef.current.style.scrollBehavior = 'auto';
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;

            setTimeout(() => {
                commentsContainerRef.current.style.scrollBehavior = 'smooth';
            }, 100);
        }
    }, [open?.isVisible, comments?.comments]);

    useEffect(() => {
        if (isOrderFlow) {
            getTaskAssign()
        }
        getUsersList()
    }, [])


    const formik = useFormik({
        initialValues: {
            Comment: "",
            OrderCommentsId: 0,
        },
        enableReinitialize: true,
        validationSchema:
            yup.object().shape({
                Comment: yup.string().required('Comment is required field'),
            }),
        onSubmit: async (values) => {
            setShowSubmitLoading(true);
            try {
                const res = await dataService('post', `OrdersCommentSave/${values?.OrderCommentsId || 0}`, {
                    Comment: values.Comment,
                    OrderId: open?.extraData?.OrderId,
                });

                if (res && res.status === 200) {
                    formik.resetForm();
                    emitEvent("getOrderComments", {
                        orderId: open?.extraData?.OrderId,
                        userId: currentUser()?.UserId
                    });
                }
            } catch (error) {
                console.error('Error submitting comment:', error);
            } finally {
                setShowSubmitLoading(false);
            }
        }
    })

    const getUsersList = async () => {
        let res = await dataService('get', 'CommonList', `Query=UsersWithLoginAccess`);
        if (res.status === 200) {
            setUserList(res?.data || [])
        }
    }

    const getTaskAssign = async () => {
        let res = await dataService('get', `TaskGetByFlowStepId/${open?.extraData?.FlowStepId}`, '');
        if (res.status === 200) {
            setViewDetailDialog({
                isVisible: false, data: res?.data || null, extraData: res?.data || null
            })
        }
    }

    const refreshComments = async () => {
        setIsRefreshing(true);
        try {
            let res = await dataService('get', `OrdersCommentsList/${open?.extraData?.OrderId}`, '');
            if (res.status === 200 && res?.data) {
                setOpen((prev) => ({
                    ...prev,
                    data: res.data,
                }));
                setIsRefreshing(false);
            }
        } catch (error) {
            console.error("Error refreshing comments:", error);
            setIsRefreshing(false);
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
    };


    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="md" fullScreen={isMobile ? false : false} aria-labelledby="simple-dialog-title" aria-describedby="alert-dialog-description" open={open?.isVisible}>
            <div className="flex flex-wrap items-center justify-between bg-gray-100 shadow-sm border-2">
                <DialogTitle id="form-dialog-title" className="pb-1 w-full" >
                    <Close
                        className="w-6 h-auto cursor-pointer absolute right-3 top-3 text-gray-500 hover:text-gray-700"
                        onClick={handleClose}
                    />
                    <div className="mr-4">
                        <Accordion sx={{ boxShadow: "none", border: "1px solid #00000020" }}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMore fontSize='2rem' sx={{ width: '30px', height: "30px", color: '#555' }} />
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ py: 0, margin: "0 !important", minHeight: "35px !important" }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

                                    <div className={`w-full ${open?.extraData?.ProductName ? "space-y-2" : ""} mt-1`}>
                                        <div className={`flex ${isMobile ? "flex-col items-start space-y-2" : "flex-row items-center"} justify-between`}>
                                            <Typography variant={isMobile ? "h5" : "h3"} className="font-medium" sx={{ m: 0 }}>
                                                <span style={{ color: '#f68220', fontWeight: 'bold' }}>#{open?.extraData?.OrderNumber}</span>
                                            </Typography>
                                            <Typography variant="body1" className="text-gray-700">
                                                {open?.extraData?.Quantity || "N/A"} {open?.extraData?.FileName} {open?.extraData?.ProductName || "N/A"} for {open?.extraData?.CustomerName}
                                            </Typography>
                                        </div>
                                    </div>
                                    {/* <IconButton
                                        sx={{
                                            color: "gray",
                                        }}

                                        onClick={(event) => {
                                            event.stopPropagation();
                                            refreshComments();
                                        }}
                                        title="Refresh Comments"
                                    >
                                        <Refresh sx={{ width: "25px", height: "25px" }} fontSize="large" />
                                    </IconButton> */}

                                </div>
                            </AccordionSummary>
                            <Divider sx={{ borderColor: "#00000020" }} />
                            <AccordionDetails>
                                <div className={`w-full ${open?.extraData?.ProductName ? "space-y-2" : ""} mt-1`}>
                                    <div className={`flex ${isMobile ? "flex-col items-start space-y-2" : "flex-row items-center"} justify-between`}>
                                        <Typography variant="body1" className="text-gray-700">
                                            <strong>Customer :</strong> {open?.extraData?.CustomerName}
                                        </Typography>
                                        <Typography variant="body1" className="text-gray-700">
                                            <strong>File Name :</strong> {open?.extraData?.FileName}
                                        </Typography>
                                    </div>

                                    <div className={`flex ${isMobile ? "flex-col items-start space-y-2" : "flex-row items-center"} justify-between`}>
                                        {open?.extraData?.ProductName && (
                                            <Typography variant="body1" className="text-gray-700">
                                                <strong>Product Name :</strong> {open?.extraData?.ProductName || "N/A"}
                                            </Typography>
                                        )}
                                        {open?.extraData?.Quantity && (
                                            <Typography variant="body1" className="text-gray-700">
                                                <strong>Quantity :</strong> {open?.extraData?.Quantity || "N/A"}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between gap-4 items-center">
                                    {viewDetailDialog.data && (
                                        <button
                                            onClick={() => {
                                                setViewDetailDialog({
                                                    data: viewDetailDialog.data,
                                                    isVisible: true,
                                                    extraData: viewDetailDialog.extraData,
                                                });
                                            }}
                                            className="mt-3 px-3 py-1.5 bg-red-500 text-white text-sm font-bold rounded-md hover:bg-red-600 transition-all"
                                        >
                                            Hold Details
                                        </button>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </DialogTitle>
            </div>

            <DialogContent sx={theme => ({
                paddingLeft: isMobile ? 0 : 0,
                paddingRight: isMobile ? 0 : 0,
            })}>

                <div className="bg-white overflow-hidden sm:rounded-lg -mt-5">
                    <div>
                        <div>
                            <div>
                                <Box
                                    ref={commentsContainerRef}
                                    sx={{
                                        maxHeight: 450,
                                        minHeight: 250,
                                        overflowY: 'auto',
                                        p: "5px",
                                        mt: "5px",
                                        overflowX: "hidden",
                                        position: 'relative',
                                    }}
                                >
                                    {isCommentsLoading ? (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                zIndex: 10,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '50%',
                                                    border: '3px solid',
                                                    borderColor: 'primary.light',
                                                    borderTopColor: 'primary.main',
                                                    animation: 'spin 1s linear infinite',
                                                    '@keyframes spin': {
                                                        '0%': { transform: 'rotate(0deg)' },
                                                        '100%': { transform: 'rotate(360deg)' },
                                                    },
                                                }}
                                            />
                                            <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                                                Loading comments...
                                            </Typography>
                                        </Box>
                                    ) : comments?.comments?.length === 0 ? (
                                        <Box
                                            sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'text.secondary',
                                                p: 3,
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ fontWeight: 500 }}>
                                                No Comments yet
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            {comments?.comments?.map((comment, index) => {
                                                const isSameUserAsPrevious =
                                                    index > 0 && comments.comments[index - 1]?.UserId === comment.UserId;

                                                const isSameUserAsNext =
                                                    index < comments.comments.length - 1 && comments.comments[index + 1]?.UserId === comment.UserId;

                                                const isCurrentUser = comment.UserId === currentUser()?.UserId;

                                                const commentDate = moment(comment.CommentAt).startOf('day'); // Reset time to 00:00
                                                const currentDate = moment().startOf('day'); // Reset time to 00:00

                                                const diffInDays = currentDate.diff(commentDate, 'days'); // Compare by date only

                                                let displayDate = "";
                                                if (diffInDays === 0) {
                                                    displayDate = "Today";
                                                } else if (diffInDays === 1) {
                                                    displayDate = "Yesterday";
                                                } else if (diffInDays <= 6) {
                                                    displayDate = commentDate.format("dddd"); // Show weekday
                                                } else {
                                                    displayDate = commentDate.format("MMMM D, YYYY"); // Show full date
                                                }

                                                const previousDate =
                                                    index > 0
                                                        ? moment(comments.comments[index - 1]?.CommentAt).startOf('day').format("YYYY-MM-DD")
                                                        : null;

                                                const currentFormattedDate = commentDate.format("YYYY-MM-DD");

                                                return (
                                                    <React.Fragment key={comment.OrderCommentsId}>
                                                        {(index === 0 || currentFormattedDate !== previousDate) && (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    margin: "10px 0",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        flex: 1,
                                                                        height: "1px",
                                                                        backgroundColor: "#E0E0E0",
                                                                        margin: "0 10px",
                                                                    }}
                                                                ></span>
                                                                <div
                                                                    style={{
                                                                        fontSize: "0.85rem",
                                                                        color: "#9E9E9E",
                                                                        fontWeight: "bold",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {displayDate}
                                                                </div>
                                                                <span
                                                                    style={{
                                                                        flex: 1,
                                                                        height: "1px",
                                                                        backgroundColor: "#E0E0E0",
                                                                        margin: "0 10px",
                                                                    }}
                                                                ></span>
                                                            </div>

                                                        )}

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: isCurrentUser ? "row-reverse" : "row",
                                                                alignItems: "flex-start",
                                                                marginBottom: "8px",
                                                                marginLeft: isCurrentUser ? 0 : "12px",
                                                                marginRight: isCurrentUser ? "12px" : 0,
                                                            }}
                                                        >
                                                            <div className="w-6 h-6 xsm:h-8 xsm:w-8 -mt-1">
                                                                {(!isSameUserAsPrevious && !isCurrentUser) && (
                                                                    <Avatar
                                                                        className={`w-5 h-5 xsm:h-7 xsm:w-7 xsm:text-[14px] text-[10px] xsm:mt-1.5 mt-3 ${isCurrentUser ? "xsm:ml-2 ml-0" : "xsm:-ml-1 ml-0"
                                                                            }`}
                                                                    >
                                                                        {comment?.CommentByName?.charAt(0)}
                                                                    </Avatar>
                                                                )}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    maxWidth: "70%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: isCurrentUser ? "flex-end" : "flex-start",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: isCurrentUser ? "flex-end" : "flex-start",
                                                                        backgroundColor: isCurrentUser ? "#80808020" : "#FFFFFF",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        paddingTop: "5px",
                                                                        paddingBottom: "3px",
                                                                        borderRadius: "10px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        borderBottomRightRadius: "10px",
                                                                        wordBreak: "break-word",
                                                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                                                        border: "1px solid #E0E0E0",
                                                                    }}
                                                                >
                                                                    {!isSameUserAsPrevious && !isCurrentUser && (
                                                                        <Typography
                                                                            variant="h5"
                                                                            style={{
                                                                                fontWeight: "bold",
                                                                                marginBottom: "4px",
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            {comment?.CommentByName}
                                                                        </Typography>
                                                                    )}
                                                                    <Typography
                                                                        variant={isMobile ? "h6" : "h5"}
                                                                        sx={{
                                                                            whiteSpace: "pre-line",
                                                                            flex: 1,
                                                                            mb: 0,
                                                                        }}
                                                                    >
                                                                        {formatMessage(comment?.Comment, currentUser()?.UserId)}
                                                                        <span
                                                                            style={{
                                                                                color: "#9E9E9E",
                                                                                fontSize: isMobile ? "0.6rem" : "0.7rem",
                                                                                float: "right",
                                                                                justifyContent: "flex-end",
                                                                                marginTop: "5px",
                                                                                marginLeft: "5px",
                                                                            }}
                                                                        >
                                                                            {moment(comment.CommentAt).format("hh:mm A")}
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    )}

                                    {isRefreshing && (
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: 3,
                                                backgroundColor: 'primary.main',
                                                animation: 'progress 2s infinite',
                                                '@keyframes progress': {
                                                    '0%': {
                                                        transform: 'translateX(-100%)',
                                                    },
                                                    '100%': {
                                                        transform: 'translateX(100%)',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                </Box>

                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Box sx={{ width: "100%", paddingRight: 1 }}>
                                        <MentionsInput
                                            placeholder="Add Comment.. type @ to mention"
                                            value={formik.values.Comment}
                                            onChange={(e) => { formik.setFieldValue('Comment', e.target.value); }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && e.ctrlKey) {
                                                    e.preventDefault();
                                                    formik.handleSubmit();
                                                }
                                            }}
                                            style={{
                                                control: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: '#fff',
                                                    fontSize: isMobile ? 10 : 12,
                                                    borderRadius: '10px',
                                                    paddingRight: '40px',
                                                    // height: "100px",
                                                    // overflowY : "auto",
                                                },
                                                '&multiLine': {
                                                    control: {
                                                        display: 'flex',
                                                    },
                                                    highlighter: {
                                                        padding: 9,
                                                        border: '1px solid transparent',
                                                    },
                                                    input: {
                                                        padding: 9,
                                                        border: '1px solid silver',
                                                        borderRadius: '10px',
                                                        flex: 1,
                                                        // maxHeight: "100px",
                                                        // overflowY: "auto",
                                                    },
                                                },
                                                '&singleLine': {
                                                    display: 'inline-block',
                                                    width: '100%',
                                                    highlighter: {
                                                        padding: 1,
                                                        border: '2px inset transparent',
                                                    },
                                                    input: {
                                                        padding: 1,
                                                        border: '2px inset',
                                                        borderRadius: '10px',
                                                        flex: 1,
                                                        // maxHeight : "100px",
                                                        // overflowY : "auto",
                                                    },
                                                },
                                                suggestions: {
                                                    list: {
                                                        position: 'absolute',
                                                        bottom: '100%',
                                                        backgroundColor: 'white',
                                                        border: '1px solid rgba(0,0,0,0.15)',
                                                        fontSize: 16,
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                        maxWidth: "300px !important",
                                                        minWidth: "180px",
                                                        zIndex: 1000,
                                                        marginBottom: '25px',
                                                    },
                                                    item: {
                                                        padding: '5px 15px',
                                                        fontSize: 14,
                                                        fonrWeight: 600,
                                                        // borderBottom: '1px solid rgba(0,0,0,0.15)',
                                                        '&focused': {
                                                            backgroundColor: '#00000020',
                                                        },
                                                    },
                                                },
                                            }}
                                            a11ySuggestionsListLabel={'Suggested mentions'}
                                        >
                                            <Mention
                                                appendSpaceOnAdd={true}
                                                data={users.map(user => ({
                                                    id: user.UserId,
                                                    display: user.Name,
                                                    key: `${user.UserId}-${user.Name}`
                                                }))}
                                                trigger="@"
                                                style={{ backgroundColor: '#2BA5CE50' }}
                                                displayTransform={(id, display) => {
                                                    return `@${display.toUpperCase()}`
                                                }}
                                            />
                                        </MentionsInput>
                                    </Box>
                                    <button
                                        type='submit'
                                        disabled={showSubmitLoading}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'green',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            display: formik.values.Comment.trim().replace(/\s/g, '').length > 0 ? 'flex' : 'none',
                                        }}
                                    >
                                        <Send sx={{ color: '#ffffff', width: '16px', height: '16px' }} />
                                    </button>

                                    {/* {formik.touched.Comment && formik.errors.Comment && (
                                        <Typography color="error" className='ml-2'>{formik.errors.Comment}</Typography>
                                    )} */}
                                </Grid>
                            </form>
                        </div>
                    </div>
                </div>
            </DialogContent>

            {viewDetailDialog?.isVisible &&
                <MyTaskDetailViewDialog
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setIsReload={setIsReload}
                    isReload={isReload}
                    open={viewDetailDialog}
                    setOpen={setViewDetailDialog}
                    onClose={() => {
                        setViewDetailDialog({ data: null, isVisible: false, extraData: null })
                    }} />}
        </Dialog >
    );
};
export default OrdersCommentsDialog;