import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  Close as CloseIcon,
  Smartphone,
} from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { dataService } from 'services/data-service';
import { currentUser } from 'services/user-service';

const DeviceDetailsDialog = ({ open, onClose, device }) => {
  const [workCenterOptions, setWorkCenterOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  useEffect(() => {
    if (open) {
      fetchWorkCenters();
      fetchUsers();
    }
  }, [open]);

  const fetchWorkCenters = async () => {
    try {
      let res = await dataService(
        'get', 
        'WorkCenterListPublic',
        'searchText=&sortBy=Order&sortOrder=asc&page=1&offset=20'
      );
      if (res?.status === 200) {
        setWorkCenterOptions(res.data.filter(wc => Number(wc.WorkCenterId) !== 16).map((work) => ({
            WorkCenterId: work.WorkCenterId,
            Name: work.Name
        })));
      } 
    } catch (error) {
      console.error("Error fetching work centers:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      let res = await dataService(
        'get',
        'UserList',
        'searchText=&sortBy=CreatedAt&sortOrder=desc&page=1&offset=1000'
      );
      if (res?.status === 200) {
        setUserOptions(res.data?.filter(user => !device?.UserOptions.has(user.UserId)));
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const newWorkCenters = values.workCenters.filter(
        (newWC) => !initialValues.workCenters.some(
          (oldWC) => oldWC.WorkCenterId === newWC.WorkCenterId
        )
      );
  
      const deletedWorkCenters = initialValues.workCenters.filter(
        (oldWC) => !values.workCenters.some(
          (newWC) => newWC.WorkCenterId === oldWC.WorkCenterId
        )
      );
  
      const payload = {
        DeviceId: device.DeviceId,
        UserId: values.userId || 0,
        DeletedWorkCenters: JSON.stringify(deletedWorkCenters), 
        NewWorkCenters: JSON.stringify(newWorkCenters),
        CreatedBy: currentUser()?.UserId,
      };
  
      const res = await dataService('post', `UpdateDeviceDetails/${device.DeviceId}`, payload);
      if (res?.status === 200) {
        onClose();
      }
    } catch (error) {
      console.error('Error updating:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    workCenters: device.WorkCenterDetails?.map((work) => ({
        WorkCenterId: work.WorkCenterId,
        Name: work.WorkCenterName
    })) || [],
    userId: device.UserDetails?.[0]?.UserId || null,
  };

  
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2.5,
          px: 2,
          backgroundColor:  "#3b82f6",
          color: 'white'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Smartphone sx={{ fontSize: 20, width: 20, height: 20, color: 'white !important' }} />
          <Typography variant="h3" sx={{ fontWeight: 600, color: 'white !important',  mb: "0 !important" }}>
            Device ({`RD-${device.DeviceId}`})
          </Typography>
        </Box>
        <IconButton 
          onClick={onClose}
          color='white'
          size="large"
          sx={{ color: 'white', }}
        >
          <CloseIcon  fontSize="large" sx={{ color: 'white !important', width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
      <Formik
  initialValues={initialValues}
  onSubmit={handleSubmit}
  enableReinitialize
>
  {({ values, setFieldValue, isSubmitting, errors, touched }) => (
    <Form> 
      <Stack spacing={3}>
        <Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1
          }}>
            <Typography variant="subtitle2" color="text.secondary">
              User 
            </Typography>
          </Box>
          <Autocomplete
            options={userOptions}
            getOptionLabel={(option) => `${option.Name}`}
            getOptionKey={(option) => option.UserId}
            value={userOptions.find(u => u.UserId === values.userId) || null}
            onChange={(_, newValue) => {
              setFieldValue('userId', newValue?.UserId || '');
            }}
            isOptionEqualToValue={(option, value) => Number(option.UserId) === Number(value.UserId)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
              />
            )}
          />
        </Box>

        <Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1
          }}>
            <Typography variant="subtitle2" color="text.secondary">
              Work Centers
            </Typography>
          </Box>

          <Autocomplete
            multiple
            options={workCenterOptions}
            getOptionLabel={(option) => option.Name}
            value={values.workCenters}
            getOptionKey={(option) => option.WorkCenterId}
            onChange={(_, newValue) => {
              setFieldValue('workCenters', newValue);
            }}
            isOptionEqualToValue={(option, value) => option.WorkCenterId === value.WorkCenterId}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
              />
            )}
          />
        </Box>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          type="submit"
          variant="contained"
          sx={{ backgroundColor: "#5e72e4", color: 'white' }}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Box>
    </Form>
  )}
</Formik>

      </DialogContent>
    </Dialog>
  );
};

export default DeviceDetailsDialog;