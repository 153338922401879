import React from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Store as StoreIcon,
  InsertDriveFile,
  ReceiptLong,
  LocalShipping,
  Assignment,
  Category,
} from "@mui/icons-material";
import Header from "components/Headers/Header";

const Inventory = () => {
  const theme = useTheme();
  const history = useHistory();

  const handleNavigation = (route) => {
    history.push(route);
  };

  const menuItems = [
    {
      title: "Materials",
      description: "Manage inventory materials and their specifications",
      icon: <Category />,
      route: "/admin/inventory-materials",
    },
    {
      title: "Stocks",
      description: "Monitor and manage inventory stock levels",
      icon: <StoreIcon />,
      route: "/admin/inventory-stocks",
    },
    {
      title: "Material Requirements",
      description: "Manage material requirements per file",
      icon: <InsertDriveFile />,
      route: "/admin/inventory-material-required-per-file",
    },
    {
      title: "Purchase Orders",
      description: "Create and manage purchase orders",
      icon: <LocalShipping />,
      route: "/admin/inventory-purchase-orders",
    },
    {
      title: "Stock Transactions",
      description: "View and track all stock movement history",
      icon: <ReceiptLong />,
      route: "/admin/inventory-stock-transactions",
    },
    {
      title: "Work Order Materials",
      description: "Track materials used in work orders",
      icon: <Assignment />,
      route: "/admin/inventory-work-order-materials",
    },
  ];

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Header isSystemSettings={true} />
      <Grid 
        container 
        spacing={2} 
        sx={{ 
          mt: 3,
          mx: 0,
          width: '100%',
          px: { xs: 2, md: 3 }
        }}
      >
        <Grid 
          item 
          xs={12}
          sm={10}
          lg={8}
          xl={6}
          sx={{
            margin: '0 auto'
          }}
        >
          <List sx={{ width: '100%' }}>
            {menuItems.map((item, idx) => (
              <ListItem
                key={idx}
                button
                onClick={() => handleNavigation(item.route)}
                sx={{
                  mb: 2,
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: theme.palette.grey[200],
                  backgroundColor: theme.palette.background.paper,
                  transition: 'all 0.3s ease',
                  width: '100%',
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: `${theme.palette.primary.main}08`,
                    '& .MuiListItemIcon-root': {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 45,
                    color: theme.palette.grey[700],
                  }}
                >
                  {React.cloneElement(item.icon, {
                    sx: { fontSize: '1.5rem', width: '1.5rem', height: '1.5rem' }
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" 
                    sx={{ fontWeight: 600, fontSize: { xs: '0.9rem', lg: '1rem' }, }}>
                      {item.title}
                    </Typography>
                  }
                  secondary={
                   <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: { xs: '0.7rem !important', lg: '0.8rem !important' },
                      }}
                    >
                      {item.description}
                    </Typography>
                  }
                  sx={{
                    '& .MuiTypography-body2': {
                      color: theme.palette.text.secondary,
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Inventory;
