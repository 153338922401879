import React, { useState, useEffect } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';


import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText, MenuItem } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { CustomButton } from "components/Common/CustomButton";
//import roles from "services/roles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(componentStyles);

function WorkCenter() {

  //Use properties
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const { id } = useParams();
  const PageTitle = id ? 'Update Workcenter' : 'Add Workcenter';

  // Usef defined states
  const [workcenterModel, setWorkcenterModel] = useState({
    Name: '',
    Status: 'Active',
    Order: 0,
    WorkingDays: 0,
  });

  const [workcenterValidateModel, setWorkcenterValidateModel] = useState({
    Name: { message: '', touched: false },
    Status: { message: '', touched: true },
    Order: { message: '', touched: true },
    WorkingDays: { message: '', touched: true },
    IsValidationError: false
  });

  const [showSubmitLoading, setShowSubmitLoading] = useState(false);

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });
  
  useEffect(() => {
    const getWorkcenterData = async () => {
      let res = await dataService("get", `WorkCenterGet/${id}`, "");
      if (res?.status === 200) {
        let WorkcenterData = res?.data;
        if (WorkcenterData) {
          setWorkcenterModel({
            ...workcenterModel,
            Name: WorkcenterData?.Name,
            Status: WorkcenterData?.Status,
            Order: WorkcenterData?.Order,
            WorkingDays: WorkcenterData?.WorkingDays,
          });
        }
      }
    };
  
    if (id) {
      getWorkcenterData(); 
    }
  }, [id]);

  const handleChange = (id, value) => {
    switch (id) {
      case 'Name':
        setWorkcenterModel({ ...workcenterModel, Name: value });
        setWorkcenterValidateModel({ ...workcenterValidateModel, Name: { message: checkEmptyValidation(value, 'Name'), touched: true } });
        break;
      case 'Order':
        setWorkcenterModel({ ...workcenterModel, Order: value });
        setWorkcenterValidateModel({ ...workcenterValidateModel, Order: { message: checkEmptyValidation(value, 'Order'), touched: true } });
        break;
      case 'WorkingDays':
        setWorkcenterModel({ ...workcenterModel, WorkingDays: value });
        setWorkcenterValidateModel({ ...workcenterValidateModel, WorkingDays: { message: checkEmptyValidation(value, 'Working Days'), touched: true } });
        break;
      case 'Status':
        setWorkcenterModel({ ...workcenterModel, Status: value });
        setWorkcenterValidateModel({ ...workcenterValidateModel, Status: { message: checkEmptyValidation(value, 'Status'), touched: true } });
        break;
      default:
        setWorkcenterModel({ ...workcenterModel });
        break;
    }
  };

  const handleSubmitWorkcenter = async () => {
    if (!checkValidations()) {
      const payload = {
        ...workcenterModel,
      }
      setShowSubmitLoading(true);
      let res = await dataService('post', `WorkCenterSave/${id ? id : 0}`, payload);
      setShowSubmitLoading(false);
      if (res && (res.status === 200 || res.status === 304)) {
        setMessageDialoge({ show: true, type: 'success', message: id ? 'Workcenter updated successfully!' : 'Workcenter registered successfully!' });
        if (!id) {
          setTimeout(() => {
            history.push('workcenters')
          }, 2000);
        }

      } else {
        //setOpen(res.message);
        setMessageDialoge({ show: true, type: 'error', message: res.message });
      }
    }
  }


  const checkValidations = () => {

    let validateErrors = {
      Name: { message: checkEmptyValidation(workcenterModel.Name, 'Name'), touched: true },
      Status: { message: checkEmptyValidation(workcenterModel.Status, 'Status'), touched: true },
      Order: { message: checkEmptyValidation(workcenterModel.Order, 'Order'), touched: true },
      WorkingDays: { message: checkEmptyValidation(workcenterModel.Order, 'Working Days'), touched: true }
    }
    if (validateErrors.Name.message !== '' ||
      validateErrors.Status.message !== '' ||
      validateErrors.Order.message !== '' ||
      validateErrors.WorkingDays.message !== '') {
      validateErrors.IsValidationError = true;
    }
    else {
      validateErrors.IsValidationError = false;
    }
    setWorkcenterValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container >
          <Grid
            item
            xs={12}
            xl={12}
            component={Box}
            marginBottom="3rem"
            classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {PageTitle}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  Workcenter Information
                </Box>
                <div className={classes.plLg4}>
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          autoComplete="off"
                          value={workcenterModel.Name}
                          onChange={e => handleChange('Name', e.target.value)}
                          error={workcenterValidateModel.Name.message !== '' && workcenterValidateModel.Name.touched}
                          helperText={workcenterValidateModel.Name.message !== '' && workcenterValidateModel.Name.touched ? workcenterValidateModel.Name.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Order</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          value={workcenterModel.Order}
                          autoComplete="off"
                          onChange={e => handleChange('Order', e.target.value)}
                          error={workcenterValidateModel.Order.message !== '' && workcenterValidateModel.Order.touched}
                          helperText={workcenterValidateModel.Order.message !== '' && workcenterValidateModel.Order.touched ? workcenterValidateModel.Order.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Working Days</FormLabel>
                        <TextField
                          variant="filled"
                          component={Box}
                          width="100%"
                          marginBottom="1rem!important"
                          value={workcenterModel.WorkingDays}
                          autoComplete="off"
                          onChange={e => handleChange('WorkingDays', e.target.value)}
                          error={workcenterValidateModel.WorkingDays.message !== '' && workcenterValidateModel.WorkingDays.touched}
                          helperText={workcenterValidateModel.WorkingDays.message !== '' && workcenterValidateModel.WorkingDays.touched ? workcenterValidateModel.WorkingDays.message : ''}
                        >
                          <Box
                            paddingLeft="0.75rem"
                            paddingRight="0.75rem"
                            component={FilledInput}
                            autoComplete="off"
                            type="text"
                          />
                        </TextField>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <FormGroup>
                        <FormLabel>Status</FormLabel>

                        <Select value={workcenterModel.Status} onChange={e => handleChange('Status', e.target.value)}>
                          <MenuItem value={'Active'}>Active</MenuItem>
                          <MenuItem value={'InActive'}>In Active</MenuItem>
                        </Select>
                      </FormGroup>
                      <FormHelperText>{workcenterValidateModel.Status.message !== '' && workcenterValidateModel.Status.touched ? workcenterValidateModel.Status.message : ''}</FormHelperText>
                    </Grid>
                  </Grid>
                 
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <CustomButton className="bg-[#5e72e4] hover:bg-[#233dd2]" loading={showSubmitLoading} onClick={handleSubmitWorkcenter} />
                      </Box>
                    </Grid>
                  </Grid>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default WorkCenter;