import React, { useContext,  useState } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Comment, DirectionsRun,  Person, VpnKey as VpnKeyIcon } from "@mui/icons-material"
// core components
import componentStyles from "assets/theme/components/navbar-dropdown.js";

import { authContext } from "../../context/AuthContext";
import { currentUser } from "services/user-service";
import ChangePasswordDialog from "../dialoges/ChangePasswordDialog";
import CustomerChangePasswordDialog from "components/dialoges/CustomerChangePasswordDialog";
import NotificationPopover from "components/dialoges/NotificationPopover";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles(componentStyles);

export default function NavbarDropdown() {
  const { setAuthData } = useContext(authContext);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUserData] = React.useState(currentUser());
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false); 

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setOpenChangePasswordDialog(true)
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    Cookies.remove('token');
    setTimeout(() => {
      //window.location = '/auth/login'
      setAuthData(null);
      history.push('auth/login')
    }, 1000);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography
        variant="h4"
        component="h4"
        classes={{ root: classes.menuTitle }}
      >
        {currentUserData?.Name}
      </Typography>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleMenuClose}
      >
        <Box
          component={Person}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>My profile</span>
      </Box>
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleChangePassword}
      >
        <Box
          component={VpnKeyIcon}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Chanage Password</span>
      </Box>
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box
        display="flex!important"
        alignItems="center!important"
        component={MenuItem}
        onClick={handleLogOut}
      >
        <Box
          component={DirectionsRun}
          width="1.25rem!important"
          height="1.25rem!important"
          marginRight="1rem"
        />
        <span>Logout</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <IconButton color="inherit" onClick={() => history.push('/admin/order-comments')}>
        <Comment color={isDesktop ? "white" : "black"}
          sx={{ cursor: "pointer", width: 25, height: 20, marginRight: 0, }}
        />
      </IconButton>
      <NotificationPopover />
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
        className="text-white flex items-center justify-center"
      >
        <Avatar
          alt="..."
          component={Person}
          className="bg-red-500"
          //src={require("assets/img/theme/team-4-800x800.jpg").default}
          classes={{
            root: classes.avatarRoot,
          }}
        />
        <Hidden mdDown>
          <Typography sx={{ mb: 0, fontSize: "0.875rem !important", fontWeight: "bold", color: isDesktop ? "white !important" : "black !important" }}>
            {currentUserData?.Name}
          </Typography>
        </Hidden>
      </Button>
      {renderMenu}

      {openChangePasswordDialog && currentUserData?.CustomerId ?
        <CustomerChangePasswordDialog title="Change Password" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} userType="loggedin" />
        :
        <ChangePasswordDialog title="Change Password" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} UserId={currentUserData?.UserId} userType="loggedin" />
      }
    </>
  );
}
