import { useState, useEffect, useRef, useCallback } from 'react';
import { currentUser } from 'services/user-service';
import { io } from 'socket.io-client';

const useSocketComment = (defaultValue = null) => {
    const [data, setData] = useState(defaultValue);
    const [isConnected, setIsConnected] = useState(false);
    const socketRef = useRef(null);
    const eventHandlers = useRef({});
    const activeOrderRef = useRef(null);

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionAttempts: 5
        });

        socketRef.current.on("connect", () => {
            setIsConnected(true);
            const userId = currentUser()?.UserId;
            if (userId) {
                socketRef.current.emit("joinRoom", userId);
            }
        });

        socketRef.current.on("connect_error", (error) => {
            setIsConnected(false);
            console.error("Socket connection error:", error);
        });

        socketRef.current.on("disconnect", () => {
            setIsConnected(false);
            console.log("Socket disconnected");
        });

        return () => cleanupSocket();
    }, []);

    const cleanupSocket = useCallback(() => {
        if (socketRef.current) {
            if (activeOrderRef.current) {
                socketRef.current.emit("leaveOrderRoom", activeOrderRef.current);
                activeOrderRef.current = null;
            }

            Object.keys(eventHandlers.current).forEach(eventName => {
                socketRef.current.off(eventName, eventHandlers.current[eventName]);
            });
            eventHandlers.current = {};

            socketRef.current.disconnect();
            socketRef.current = null;
        }
    }, []);

    const emitEvent = useCallback((eventName, payload) => {
        if (!socketRef.current?.connected) {
            console.error("Socket not connected");
            return;
        }
        try {
            socketRef.current.emit(eventName, payload);
        } catch (error) {
            console.error(`Error emitting ${eventName}:`, error);
        }
    }, []);

    const listenToEvent = useCallback((eventName, callback) => {
        if (!socketRef.current) return () => {};

        if (eventHandlers.current[eventName]) {
            socketRef.current.off(eventName, eventHandlers.current[eventName]);
        }

        const handler = (response) => {
            try {
                if (callback) {
                    callback(response);
                } else {
                    setData(response);
                }
            } catch (error) {
                console.error(`Error handling ${eventName}:`, error);
            }
        };

        eventHandlers.current[eventName] = handler;
        socketRef.current.on(eventName, handler);

        return () => {
            if (socketRef.current) {
                socketRef.current.off(eventName, handler);
                delete eventHandlers.current[eventName];
            }
        };
    }, []);

    const joinOrderRoom = useCallback((orderId) => {
        if (!orderId) return;

        if (activeOrderRef.current && activeOrderRef.current !== orderId) {
            socketRef.current?.emit("leaveOrderRoom", activeOrderRef.current);
        }

        activeOrderRef.current = orderId;
        emitEvent("joinOrderRoom", orderId);
    }, [emitEvent]);

    return { 
        data, 
        setData, 
        emitEvent, 
        listenToEvent,
        joinOrderRoom,
        connected: isConnected
    };
};

export default useSocketComment;
