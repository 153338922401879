import React from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Group as GroupIcon,
  Settings as SettingsIcon,
  Business,
  AccountBalance,
  Tune,
  CardTravel,
  Person,
  Work,
  FileCopyOutlined,
  CheckCircle,
  FactCheck,
  PanTool,
  MenuBook,
  ExpandMore,
} from "@mui/icons-material";
import Header from "components/Headers/Header";

const SystemSettings = () => {
  const theme = useTheme();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState('master_settings');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleListItemClick = (route) => {
    history.push(route);
  };

  const ListItemSX = {
    mb: 1.5,
    borderRadius: 1.5,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.3s ease-in-out',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    py: 1,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.primary.main,
    },
    '&:last-child': {
      mb: 0,
    },
  };

  const AccordionSX = {
    mb: 1,
    borderRadius: '12px !important',
    '&:before': {
      display: 'none',
    },
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    '&.Mui-expanded': {
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      margin: '0 0 24px 0',
    },
  };

  const sections = [
    {
      id: 'master_settings',
      title: "Master Settings",
      description: "Core system configurations",
      icon: <SettingsIcon />,
      items: [
        {
          title: "Users Management",
          description: "User access and roles",
          icon: <GroupIcon />,
          route: "/admin/users",
        },
        {
          title: "Work Centers",
          description: "Work center setup",
          icon: <AccountBalance />,
          route: "/admin/workcenters",
        },
        {
          title: "Machines",
          description: "Machine settings",
          icon: <Tune />,
          route: "/admin/machines",
        },
        {
          title: "Products",
          description: "Product management",
          icon: <CardTravel />,
          route: "/admin/products",
        },
        {
          title: "Customers",
          description: "Customer profiles",
          icon: <Person />,
          route: "/admin/customers",
        },
        {
          title: "Devices",
          description: "Device settings",
          icon: <FactCheck />,
          route: "/admin/devices",
        },
      ],
    },
    {
      id: 'production_settings',
      title: "Production Settings",
      description: "Production parameters",
      icon: <Business />,
      items: [
        {
          title: "File Size Settings",
          description: "File specifications",
          icon: <Work />,
          route: "/admin/product-files",
        },
        {
          title: "Files Management",
          description: "System files",
          icon: <FileCopyOutlined />,
          route: "/admin/files",
        },
        {
          title: "Hold Reasons",
          description: "Production holds",
          icon: <PanTool />,
          route: "/admin/hold-reasons",
        },
        {
          title: "Catalogue Settings",
          description: "Product catalogues",
          icon: <MenuBook />,
          route: "/admin/catalogues",
        },
      ],
    },
    {
      id: 'check_list',
      title: "Check List",
      description: "Inspection settings",
      icon: <FactCheck />,
      items: [
        {
          title: "Flow Check List",
          description: "Flow parameters",
          icon: <CheckCircle />,
          route: "/admin/flow-check-list",
        },
        {
          title: "GE Check List",
          description: "Entry inspection",
          icon: <FactCheck />,
          route: "/admin/goods-entry-check-list",
        },
      ],
    },
  ];

  return (
    <>
      <Header isSystemSettings={true}/>
      <Grid container spacing={{ xs: 1, md: 1 }} sx={{ mt: { xs: 2, md: 3 }, px: { xs: 2, md: 4 } }}>
        {sections.map((section) => (
          <Grid item xs={12} sm={12} lg={6} xl={4} key={section.id}>
            <Accordion
              expanded={expanded === section.id}
              onChange={handleAccordionChange(section.id)}
              sx={AccordionSX}
            >
              <AccordionSummary
                expandIcon={<ExpandMore sx={{ fontSize: '1.25rem' }} />}
                sx={{
                  minHeight: { xs: 45, md: 50 },
                  backgroundColor: expanded === section.id ?
                    `${theme.palette.primary.main}15` : theme.palette.grey[50],
                  borderRadius: '12px 12px 0 0',
                  '& .MuiAccordionSummary-content': {
                    margin: { xs: '12px 0', md: '16px 0' },
                  },
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      backgroundColor: expanded === section.id ?
                        theme.palette.primary.main : theme.palette.grey[300],
                      borderRadius: '8px',
                      p: { xs: 0.75, md: 1 },
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {React.cloneElement(section.icon, { 
                      color: expanded === section.id ? 'white' : 'inherit', 
                      sx: { fontSize: { xs: '1.25rem', md: '1.5rem' } }
                    })}
                  </Box>
                  <Stack spacing={0.25}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: '0.9rem', lg: '1rem' },
                        fontWeight: 600,
                        color: expanded === section.id ?
                          theme.palette.primary.main : theme.palette.text.primary,
                      }}
                    >
                      {section.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: { xs: '0.7rem !important', lg: '0.8rem !important' },
                      }}
                    >
                      {section.description}
                    </Typography>
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ p: { xs: 2, md: 3 } }}>
                <List disablePadding>
                  {section.items.map((item, idx) => (
                    <ListItemButton
                      key={idx}
                      onClick={() => handleListItemClick(item.route)}
                      sx={{
                        ...ListItemSX,
                        py: { xs: 0.75, md: 1 },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: { xs: 35, md: 45 },
                          color: theme.palette.primary.main,
                        }}
                      >
                        {React.cloneElement(item.icon, { 
                          sx: { 
                            fontSize: { xs: '1.1rem', md: '1.3rem' },
                            width: { xs: '1.1rem', md: '1.3rem' },
                            height: { xs: '1.1rem', md: '1.3rem' },
                          }
                        })}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              fontWeight: 500,
                              fontSize: { xs: '0.85rem', lg: '0.95rem' },
                            }}
                          >
                            {item.title}
                          </Typography>
                        }
                        secondary={
                          <Typography 
                            sx={{ 
                              fontSize: { xs: '0.6rem !important', lg: '0.7rem !important' },
                              color: theme.palette.text.secondary,
                            }}
                          >
                            {item.description}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SystemSettings;
